.previewNavbarModal {
    width: 475px;
    height: 544px;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    position: relative;
}

.previewHeader {
    max-height: 313px;
    overflow: hidden;
    border-radius: 20px;
}

.previewAnimation {
    height: auto;
    width: 100%;
}

.navigationDots {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.dots {
    border: 0.65px solid #dddddd;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.activeDots {
    background-color: #ddd;
}

.navigationBody {
    margin-top: 10px;
    color: #101828;
    text-align: center;
}

.navigationBody .cardHeading {
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.navigationBody .cardDisc {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
}

.btnGroup {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.previewNavbarModal .previewBtn {
    min-width: 93px;
    border-radius: 4px;
}

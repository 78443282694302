/* By default this modal will be open in center for desktop and tablet size, and for mobile it will stick to bottom of the screen for easy access */
.modalInnerContainer {
    position: absolute;
    min-width: auto;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3px 6px -4px #0000001f, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000d;
    border-radius: 0px;
    border: none;
    outline: none;
}

@media only screen and (max-width: 480px) {
    .modalInnerContainer {
        transform: none;
        top: initial;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .modalMobileFullScreen {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
